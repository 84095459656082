import React from 'react'
import { Tabs, Tab, Container, Card } from 'react-bootstrap'

import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap-icons/font/bootstrap-icons.css'
import { NavBar } from './navBar'

function App() {
  return (
    <>
      <NavBar key="0" />
      <div className="container">
        <Tabs
          key="1"
          defaultActiveKey="home"
          id="uncontrolled-tab-example"
          className="mb-3"
        >
          <Tab
            key="2"
            eventKey="home"
            title="Информация"
          >
            <Container key="3">
              <Card key="4">
                <Card.Body key="5">
                  <Card.Body>
                    <div>
                      <strong>
                        Данная ссылка предназначена для скачивания
                        <br /> Мобильного приложения INTERSTONE на моб.
                        устройствах
                        <br /> с iОS или Android.
                        <br /> Если вы хотите скачать Мобильное приложение -
                        откройте данную <br />
                        <a href="https://onelink.to/wdvh5a">ссылку</a> на вашем
                        моб. устройстве
                      </strong>
                    </div>
                  </Card.Body>
                </Card.Body>
              </Card>
            </Container>
          </Tab>
        </Tabs>
      </div>
    </>
  )
}

export default App
